/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/jquery@3.4.1/dist/jquery.min.js
 * - /npm/bootstrap@5.1.3/dist/js/bootstrap.bundle.js
 * - /npm/gsap@3.8.0/dist/gsap.min.js
 * - /npm/gsap@3.8.0/dist/ScrollTrigger.min.js
 * - /npm/gsap@3.8.0/dist/ScrollToPlugin.min.js
 * - /npm/swiper@6.8.4/swiper-bundle.min.js
 * - /npm/@fancyapps/fancybox@3.5.7/dist/jquery.fancybox.min.js
 * - /npm/jquery-throttle-debounce@1.0.0/jquery.ba-throttle-debounce.min.js
 * - /npm/plyr@3.6.9/dist/plyr.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
